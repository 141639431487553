<template>
  <v-card class="mx-2">
    <v-toolbar>
      <v-toolbar-title class="text-h6" text="Doador Ouro"></v-toolbar-title>

      <template v-slot:append>
        <v-btn
          append-icon="mdi mdi-qrcode"
          @click="carteirinha = true"
          color="red"
          variant="outlined"
        >
          Cateirinha
        </v-btn>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <div class="font-weight-bold ms-1 mb-2">Proxima Doação</div>
      <v-alert
        v-if="aptoDoacao"
        border="start"
        icon="mdi mdi-check-circle"
        color="success"
        variant="tonal"
        class="mb-2"
      >
        Você está apto a doar! Veja as orientações de como se preparar
        <a href="/orientacoes">clicando aqui</a>.
      </v-alert>
      <v-alert
        v-else
        border="start"
        icon="mdi mdi-alert-circle"
        color="warning"
        variant="tonal"
        class="mb-2"
      >
        Você ainda não está apto a doar, sua próxima doação poderá ser realizada
        à partir do dia 31/12/3458.
      </v-alert>

      <div class="font-weight-bold ms-1 mb-2">Doações</div>

      <v-timeline align="start" density="compact">
        <v-timeline-item
          v-for="message in messages"
          :key="message.time"
          dot-color="red"
          size="x-small"
        >
          <div class="mb-4">
            <div class="font-weight-normal">
              <strong>{{ message.date }}</strong>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="outlined">Ver Todas</v-btn>
    </v-card-actions>
  </v-card>

  <v-dialog
    transition="dialog-bottom-transition"
    v-model="carteirinha"
    fullscreen
  >
    <v-card>
      <v-toolbar color="amber-lighten-3">
        <v-btn icon dark @click="carteirinha = false">
          <v-icon icon="mdi mdi-close"></v-icon>
        </v-btn>
        <v-toolbar-title>Carteirinha</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card class="mx-2 mt-2">
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-img
                :src="data_usuario.avatar"
                aspect-ratio="3/4"
                max-width="100"
                cover
              />
            </v-col>
            <v-col>
              <v-list>
                <v-list-item
                  title="Nome"
                  :subtitle="data_usuario.nome"
                ></v-list-item>
                <v-list-item
                  title="CPF"
                  :subtitle="data_usuario.cpf"
                ></v-list-item>
                <v-list-item
                  title="Data de Nascimento"
                  :subtitle="
                    data_usuario.data_nascimento.split('-').reverse().join('/')
                  "
                ></v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="d-flex flex-wrap mx-2 mt-2">
        <v-card-text>
          <v-list density="compact">
            <v-list-subheader>INFORMAÇÕES DO DOADOR</v-list-subheader>
            <v-list-item color="primary">
              <template v-slot:prepend>
                <v-icon icon="mdi mdi-clock"></v-icon>
              </template>
              <v-list-item-title>Doador desde 01/01/1890</v-list-item-title>
            </v-list-item>
            <v-list-item color="primary">
              <template v-slot:prepend>
                <v-icon icon="mdi mdi-blood-bag"></v-icon>
              </template>
              <v-list-item-title>3545 Doações</v-list-item-title>
            </v-list-item>
            <v-list-item color="primary">
              <template v-slot:prepend>
                <v-icon icon="mdi mdi-water-check"></v-icon>
              </template>
              <v-list-item-title
                >6756 Hemocomponentes Gerados</v-list-item-title
              >
            </v-list-item>
            <v-list-item color="primary">
              <template v-slot:prepend>
                <v-icon
                  icon="mdi mdi-heart"
                  color="red"
                  size="x-large"
                ></v-icon>
              </template>
              <v-list-item-title>10999 Vidas Salvas!</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text>
          <QRCodeVue3 value="https://hemoam.am.gov.br" myclass="text-center" />
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import QRCodeVue3 from "qrcode-vue3";

export default {
  components: {
    QRCodeVue3,
  },
  data: () => ({
    data_usuario: {},
    carteirinha: false,
    messages: [
      {
        date: new Date().toLocaleString("pt-br"),
      },
      {
        date: "07/12/2023, 11:02:00",
      },
      {
        date: "01/02/2023, 09:50:30",
      },
    ],
  }),
  computed: {
    aptoDoacao() {
      return true;
    },
  },
  created: function () {
    this.get_usuario();
  },
  methods: {
    get_usuario() {
      if (!localStorage.getItem("usuario")) {
        this.loading = true;
        axios
          .get(process.env.VUE_APP_API_BASE_URL + "/usuario", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            localStorage.setItem("usuario", JSON.stringify(response.data));
            this.data_usuario = response.data;
          })
          .catch(() => {
            this.message = {
              style: "error",
              text: "Erro ao carregar informações do usuário.",
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.data_usuario = JSON.parse(localStorage.getItem("usuario"));
      }
    },
  },
};
</script>
