import axios from "axios";

async function post_login(usuario) {
  return await axios.post(
    process.env.VUE_APP_API_BASE_URL + "/usuario/login",
    usuario
  );
}

async function get_usuario() {
  return await axios.get(process.env.VUE_APP_API_BASE_URL + "/usuario", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

const client = {
  post_login,
  get_usuario,
};

export default client;
