<template>
  <v-navigation-drawer v-model="drawer">
    <v-list-item title="Portal do Doador"></v-list-item>
    <v-divider></v-divider>
    <v-list-item
      link
      title="Sair"
      prepend-icon="mdi mdi-exit-to-app"
      @click="logout"
    ></v-list-item>
  </v-navigation-drawer>

  <v-app-bar>
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-app-bar-title>Olá, {{ primeiroNome }}</v-app-bar-title>

    <template #append>
      <v-btn size="small" icon :to="{ name: 'inbox' }" class="mr-1">
        <v-badge dot color="red">
          <v-icon icon="mdi mdi-bell" color="primary"></v-icon>
        </v-badge>
      </v-btn>
      <v-spacer class="mx-1"></v-spacer>
      <v-btn size="small" icon :to="{ name: 'profile' }">
        <v-avatar v-if="data_usuario.avatar" size="small" class="mx-2">
          <v-img
            :src="data_usuario.avatar"
            @error="errorHandler(data_usuario.uid)"
            alt="Foto de perfil"
            cover
          ></v-img>
        </v-avatar>
        <v-avatar
          v-else
          size="small"
          class="mx-2"
          icon="mdi mdi-account"
          color="error"
        ></v-avatar>
      </v-btn>
    </template>
  </v-app-bar>
  <v-main>
    <div class="mx-2 my-3">
      <Alert
        v-if="message"
        :style="message.style"
        :text="message.text"
        @click:close="this.message = null"
      />
    </div>
    <CarteirinhaComponent />
    <Loading v-model="loading" />
  </v-main>
</template>

<script setup>
import { ref } from "vue";

const drawer = ref(null);
</script>

<script>
import CarteirinhaComponent from "@/components/CarteirinhaComponent.vue";
import Loading from "@/components/Loading.vue";
import Alert from "@/components/Alert.vue";
import client from "@/plugins/axios";

export default {
  data: () => ({
    loading: false,
    message: null,
    drawer: null,
    data_usuario: {},
  }),
  beforeCreate: function () {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
  },
  created: function () {
    this.getUsuario();
  },
  components: {
    CarteirinhaComponent,
  },
  methods: {
    getUsuario() {
      this.loading = true;
      if (
        !localStorage.getItem("usuario") ||
        localStorage.getItem("usuario").exp < Date.now()
      ) {
        client
          .get_usuario()
          .then((res) => {
            let data = res.data;
            data.exp = Date.now() + 24 * 60 * 60;
            localStorage.setItem("usuario", data);
            this.data_usuario = data;
          })
          .catch(() => {
            this.message = {
              style: "error",
              text: "Erro ao carregar informações do usuário.",
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.data_usuario = JSON.parse(localStorage.getItem("usuario"));
        this.loading = false;
      }
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("usuario");
      this.$router.push("/login");
    },
  },
  computed: {
    primeiroNome() {
      if (!this.data_usuario.nome) return "";
      let pnome = String(this.data_usuario.nome).split(" ")[0].toLowerCase();
      return pnome.charAt(0).toUpperCase() + pnome.slice(1);
    },
  },
};
</script>
